.colorPrimary {
  background-color: #4B3274 !important; }

.colorPrimaryDark {
  background-color: rgba(75, 50, 116, 0.9) !important; }

.test-bt {
  background-color: #4B3274 !important; }

body {
  margin: 0 !important;
  color: #4B3274; }

.app_container {
  min-height: 70vh;
  padding-top: 10px;
  padding-right: 1.5em;
  padding-left: 1.5em; }

.mobile_app_container {
  min-height: 70vh;
  padding-top: 8em;
  padding-right: 1.5em;
  padding-left: 1.5em; }

footer {
  padding: 1.5em;
  text-align: center;
  color: #4B3274; }

.autcomplete-width-toolbar {
  text-align: center;
  flex: 1; }

.logout-btn {
  margin-left: 4em !important; }

.btn-header-menu {
  text-transform: none !important;
  padding: 1em !important;
  font-weight: 500 !important;
  border-radius: 0px !important;
  color: #ffffff !important; }

.stripped-table tr:nth-of-type(odd) {
  background: #F5F5F5; }

.stripped-table tbody tr:nth-child(even) {
  background-color: #e5eaec; }

.progess-load-more {
  width: 100%;
  text-align: -webkit-center; }

.table-head-bookings {
  background: #263238 !important; }
  .table-head-bookings th {
    color: #ffffff; }

.table-head-dates {
  background: #263238 !important; }
  .table-head-dates th {
    color: #ffffff; }

.very-small-btn {
  padding: 2px 9px !important;
  min-width: 52px !important;
  font-size: 0.6125rem !important;
  min-height: 20px !important;
  margin: 2px !important; }

.login-grid {
  text-align: center;
  text-align: -webkit-center; }

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.label_error {
  color: #B71C1C; }

.not_found_container {
  font-size: 70px;
  text-align: center;
  padding: 2em;
  color: #78455a64; }

.overlay-dates {
  height: 3.5em;
  width: 28em;
  display: table;
  padding-top: 1em;
  position: absolute;
  top: 0;
  left: 0;
  background: #2c3e50;
  color: #ffffff;
  pointer-events: none;
  text-align: center;
  text-align: -webkit-center;
  vertical-align: middle; }

.overlay-dates-mobile {
  height: 3.5em;
  width: 10em;
  display: table;
  padding-top: 1em;
  position: absolute;
  top: 0;
  left: 0;
  background: #2c3e50;
  color: #ffffff;
  pointer-events: none;
  text-align: center;
  text-align: -webkit-center;
  vertical-align: middle; }

.main-date-div {
  position: relative;
  display: inline-block; }
  .main-date-div input {
    width: 28em;
    height: 4em;
    border: none;
    padding: 8px;
    display: inline-block;
    cursor: pointer;
    color: transparent; }

.main-date-div-mobile {
  position: relative;
  display: inline-block; }
  .main-date-div-mobile input {
    width: 10em;
    height: 4em;
    border: none;
    padding: 8px;
    display: inline-block;
    cursor: pointer;
    color: transparent; }

.center {
  text-align: center !important;
  text-align: -webkit-center !important; }

.sidebar-icon {
  align-content: center;
  justify-self: center; }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.header-config {
  border-left-width: 0px !important;
  border-right-width: 0px !important;
  border-top-width: 0px !important;
  top: 0;
  left: 0; }
  .header-config .segment {
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    border: 0;
    border-radius: 0px !important; }

.nav-padding {
  padding: 1.8em !important;
  border-radius: 0px !important; }

.ui.input input {
  padding: .2em; }

.ui.compact.table td, .ui.compact.table th {
  padding: 0.1em; }

.vl {
  border-left: 6px solid green;
  height: 500px; }

.cell-width {
  width: 60px !important;
  height: 40px;
  background-color: #f5f4f4;
  border-radius: 0px; }
  .cell-width .input {
    width: inherit;
    text-align: center;
    border-radius: inherit !important; }
  .cell-width input {
    border-radius: inherit !important; }
  .cell-width button {
    border-radius: inherit !important; }

.tncInput:focus {
  outline: none !important;
  border-color: #ffffff;
  box-shadow: 0 0 10px #ffffff; }

.rate-date-container {
  display: -webkit-box !important;
  padding: 1em 0 0 1em !important;
  margin-bottom: 0 !important; }
  .rate-date-container input {
    margin: 0;
    max-width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    padding: .67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: .28571429rem;
    -webkit-transition: border-color .1s ease,-webkit-box-shadow .1s ease;
    transition: border-color .1s ease,-webkit-box-shadow .1s ease;
    transition: box-shadow .1s ease,border-color .1s ease;
    transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
    -webkit-box-shadow: none;
    box-shadow: none; }

.react-datepicker-popper {
  z-index: 9999 !important; }

.rate-date-container-mobile {
  display: -webkit-box !important;
  margin-bottom: 0 !important; }
  .rate-date-container-mobile input {
    margin: 0;
    max-width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    padding: .67857143em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: .28571429rem;
    -webkit-transition: border-color .1s ease,-webkit-box-shadow .1s ease;
    transition: border-color .1s ease,-webkit-box-shadow .1s ease;
    transition: box-shadow .1s ease,border-color .1s ease;
    transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
    -webkit-box-shadow: none;
    box-shadow: none; }

.no-border td {
  border: 0 !important; }

.weekend-color {
  background-color: #f5f4f433 !important; }

.header-icon {
  width: 15em;
  margin-right: 4em !important; }

.profileup {
  background-color: #B71C1C !important; }

.radio-button {
  float: right; }

.amenity-name {
  padding-left: 1.0em; }

.admin-dashboard-filter {
  min-height: 70vh !important;
  top: 0 !important;
  width: 200px !important;
  background-color: #f2f2f2 !important; }

.admin-dashboard {
  min-height: 70vh !important; }

.details-board {
  background-color: #ffffff !important;
  width: 600px !important; }

.close-btn {
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer !important; }

.segments-styles {
  margin: 0px !important;
  padding: 0px !important; }

.left-segment {
  margin: 0px !important;
  padding: 0px !important;
  min-width: 200px !important;
  max-width: 200px !important; }

.right-segment {
  margin: 0px !important;
  padding: 0px !important; }

.dashboard-submit-btn {
  background-color: #4B3274 !important;
  color: #ffffff !important; }

.admin-Dashboard-DatePicker {
  float: left !important;
  max-width: 166.2px !important;
  margin: 0px !important; }

.admin-booking-details-header {
  width: 600px !important; }

.booking-id-heading {
  font-size: 18px !important; }

.admin-dashboard-booking-id-details {
  font-size: 11px !important;
  width: 600px !important;
  margin: 0px !important;
  padding: 0px !important; }

.details-table-coloumns-left {
  padding-left: 30px !important;
  text-align: left !important; }

.details-table-coloumns-right {
  padding-right: 20px !important;
  max-width: 200px;
  word-wrap: break-word;
  text-align: justify !important;
  font-weight: 900 !important; }

.admin-is-cancellation {
  text-align: center; }

.admin-booking-details-table tr th {
  background-color: #CFD8DC !important;
  padding: 10px 30px !important;
  text-align: left !important; }

.admin-transactions-details-table {
  font-size: 10px;
  width: 400px !important;
  margin: 0px !important;
  padding: 0px !important;
  float: right !important; }

.admin-transactions-details-table tr th {
  background-color: #CFD8DC !important;
  padding: 10px 30px !important;
  text-align: left !important; }

.booking-details-heading {
  padding-top: 40px !important; }

.spacer {
  padding: 9px !important; }

.count-styles {
  background-color: transparent !important;
  border-style: 1px solid #CFD8DC !important; }

.count-label {
  float: left !important;
  font-size: 12px !important; }

.count-left {
  color: #4B3274 !important;
  font-weight: 900 !important;
  font-size: 12px !important;
  float: right !important; }

.count-right {
  background-color: #4B3274 !important;
  color: #4B3274 !important;
  font-weight: 900 !important;
  font-size: 12px !important; }

.pagination {
  list-style-type: none !important; }

.pagination li {
  float: left !important;
  border: 1px solid #CFD8DC !important;
  padding: 10px !important; }

.pagination li:hover {
  background-color: rgba(75, 50, 116, 0.9) !important; }

.pagination li:hover a {
  color: #ffffff !important; }

.pagination .active {
  background-color: #4B3274 !important; }

.pagination .active a {
  color: #ffffff !important; }

.divStyle {
  position: sticky !important;
  top: 0 !important; }

.admin-booking-table {
  font-size: 12px !important;
  margin-left: 1em !important; }

.booking-amount {
  font-weight: 900 !important; }

.divider-heigth {
  border-top: 15px solid rgba(34, 36, 38, 0.15) !important; }

.input-text-bold input {
  font-weight: bold; }
